$(function () {

    // get colors
    let tourneesColor = php_vars.tourneesColor;
    let deps = php_vars.loginDeps;
    let markerIcon = php_vars.markerIconPath;
    console.log(markerIcon);

    let newDeps = deps.map(v => v.code_departement.toString().padStart(5, 'FR-'));
    let depsColor = []
    newDeps.forEach(dep => {
        let idx = dep
        depsColor[idx] = tourneesColor
    })
  
    // Get tournees departments
    if ($('#agences_json').html() !== undefined) {
        // const tourneesJson = JSON.parse($('#tournees_json').html());
        // let tournees = tourneesJson.map(v => v.title.slice(0, 2));
        // tournees = tournees.filter((v, i, a) => a.indexOf(v) === i);
        // let regionsToColorized = [];
        // tournees.forEach(tournee => {
        //     let idx = tournee.padStart(5, 'FR-');
        //     regionsToColorized[idx] = tourneesColor;
        // })

        // Get agencies departments
         const agencesJson = JSON.parse($('#agences_json').html());
         console.log(agencesJson);
        // let agences = agencesJson.map(v => v.numero_et_departement.slice(1, 3));
        // agences = agences.filter((v, i, a) => a.indexOf(v) === i);
        // agences.forEach(agence => {
        //     let idx = agence.padStart(5, 'FR-');
        //     regionsToColorized[idx] = agencyColor;
        // })

        // Get markers
        const markers = agencesJson.map(agence => {
            return {
                latLng: [agence.latitude, agence.longitude],
                name: agence.title,
                permalink: agence.permalink
            }
        });

        let map = new jvm.Map({
            container: $('#french_map'),
            map: 'fr_merc',
            backgroundColor: 'white',
            enableZoom: false,
            zoomOnScroll: false,
            hoverColor: true,
            markerStyle: {
                initial: {
                    image: markerIcon,
                },
            },
            series: {
                regions: [{
                    attribute: 'fill',
                    values: depsColor,
                }]
            },
            regionStyle: {
                initial: {
                    fill: '#D2EDFF'
                },
                hover: {
                    "fill-opacity": 1,
                    cursor: 'arrow',
                },
                selected: {
                    fill: '#cccccc'
                }
            },
             markers: markers,
            // prevent tooltip display on region hover
            onRegionTipShow: function (e, tip, code) {
                e.preventDefault();
            },
            // Add link on markers
            onMarkerClick: function (e, code) {
                window.location.href = markers[code].permalink;
            }
        });

        // Colorize map
        //console.log('regions:', regionsToColorized);
        //map.series.regions[0].setValues(newDeps);
    }
});
