
(function(){
    var burger = document.querySelector('.burger-container'),
    logo = document.querySelector('.logo'),
        header = document.querySelector('.header');
    
    burger.onclick = function() {
        header.classList.toggle('menu-opened'),
        logo.classList.toggle('hide');
    }
}());

    
// Apply changes on menu on scroll
$(function() {
    //caches a jQuery object containing the header element
    var menu = $(".m-menu-apple-index");
    $(window).scroll(function() {
        var scroll = $(window).scrollTop();

        if (scroll >= 100) {
            menu.addClass("scrolled");
        } else {
            menu.removeClass("scrolled");
        }
    });
});
