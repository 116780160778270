if(document.body.classList.contains('home')){
    document.querySelector('.border2').addEventListener('mouseenter', function(){
    document.querySelector('.border2').classList.toggle('hover99');
    document.querySelector('.fig2').classList.toggle('hover99');
    document.querySelector('.caption2').style.opacity = 1;
    });

    document.querySelector('.border2').addEventListener('mouseleave', function(){
    document.querySelector('.border2').classList.toggle('hover99');
    document.querySelector('.fig2').classList.toggle('hover99');
    document.querySelector('.caption2').style.opacity = 0;
    });

    document.querySelector('.border1').addEventListener('mouseover', function(){
    document.querySelector('.border1').classList.toggle('hover99');
    document.querySelector('.fig1').classList.toggle('hover99');
    document.querySelector('.caption1').style.opacity = 1;
    });

    document.querySelector('.border1').addEventListener('mouseout', function(){
     document.querySelector('.border1').classList.toggle('hover99');
    document.querySelector('.fig1').classList.toggle('hover99');
    document.querySelector('.caption1').style.opacity = 0;
    });

    document.querySelector('.border3').addEventListener('mouseover', function(){
     document.querySelector('.border3').classList.toggle('hover99');
    document.querySelector('.fig3').classList.toggle('hover99');
    document.querySelector('.caption3').style.opacity = 1;
    });

    document.querySelector('.border3').addEventListener('mouseout', function(){
    document.querySelector('.border3').classList.toggle('hover99');
    document.querySelector('.fig3').classList.toggle('hover99');
    document.querySelector('.caption3').style.opacity = 0;
    });

    const modalTriggers = document.querySelectorAll('.popup-trigger')
    const modalCloseTrigger = document.querySelector('.popup-modal__close')
    const bodyBlackout = document.querySelector('.body-blackout')
    var vid = document.getElementById('home-video')


    modalTriggers.forEach(trigger => {
        trigger.addEventListener('click', () => {
            const { popupTrigger } = trigger.dataset
            const popupModal = document.querySelector('.popup-modal')

            popupModal.classList.add('is--visible')
            bodyBlackout.classList.add('is-blacked-out')
            vid.currentTime = trigger.getAttribute('data-start');
            console.log('startat :', trigger.getAttribute('data-start'))
            vid.play()
            
            popupModal.querySelector('.popup-modal__close').addEventListener('click', () => {
            popupModal.classList.remove('is--visible')
            bodyBlackout.classList.remove('is-blacked-out')
            vid.pause()
            })
            
            bodyBlackout.addEventListener('click', () => {
                // TODO: Turn into a function to close modal
                popupModal.classList.remove('is--visible')
                bodyBlackout.classList.remove('is-blacked-out')
            })
        })
    })

}


