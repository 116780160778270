$(function() {
  if (document.querySelector('.page-template-page-timeline')) {
    window.scrollTo( {
      top: 0,
      left: 0,
      behavior: 'smooth'
    } );

    const mapSequenceImages = [];

    const images = document.querySelectorAll('.speak');

    if (images) {
      Array.prototype.map.call(images, function(obj){
        mapSequenceImages.push(obj.dataset.image);
      });
    }

    const requestAnimationFrame = window.requestAnimationFrame || window.mozRequestAnimationFrame || window.webkitRequestAnimationFrame || window.msRequestAnimationFrame;

    class EventEmitter {
      listeners = {}
      addListener(eventName, fn) {
        this.listeners[eventName] = this.listeners[eventName] || [];
        this.listeners[eventName].push(fn);
        return this;
      }
      on(eventName, fn) {
        return this.addListener(eventName, fn);
      }
      once(eventName, fn) {
        this.listeners[eventName] = this.listeners[eventName] || [];
        const onceWrapper = () => {
          fn();
          this.off(eventName, onceWrapper);
        }
        this.listeners[eventName].push(onceWrapper);
        return this;
      }
      off(eventName, fn) {
        return this.removeListener(eventName, fn);
      }
      removeListener (eventName, fn) {
        let lis = this.listeners[eventName];
        if (!lis) return this;
        for(let i = lis.length; i > 0; i--) {
          if (lis[i] === fn) {
            lis.splice(i,1);
            break;
          }
        }
        return this;
      }
      emit(eventName, ...args) {
        let fns = this.listeners[eventName];
        if (!fns) return false;
        fns.forEach((f) => {
          f(...args);
        });
        return true;
      }
      listenerCount(eventName) {
        let fns = this.listeners[eventName] || [];
        return fns.length;
      }
      rawListeners(eventName) {
        return this.listeners[eventName];
      }
    }
    class Canvas {
      constructor(e) {
        this.images = e.images;
        this.container = e.container;
        this.cover = e.cover;
        this.displayIndex = 0;
      }

      setup() {
        this.canvas = document.createElement("canvas");
        this.container.appendChild(this.canvas);
        this.ctx = this.canvas.getContext('2d')

        window.addEventListener('resize', () => this.resize());
        this.resize();
      }

      renderIndex(e) {
        if (this.images[e]) {
          return this.drawImage(e);
        }
        // Find closest loaded image
        for (var t = Number.MAX_SAFE_INTEGER, r = e; r >= 0; r--)
          if (this.images[r]) {
            t = r;
            break
          }
        for (var n = Number.MAX_SAFE_INTEGER, i = e, o = this.images.length; i < o; i++)
          if (this.images[i]) {
            n = i;
            break
          }
        this.images[t] ? this.drawImage(t) : this.images[n] && this.drawImage(n)
      }

      drawImage(e) {
        this.displayIndex = e,this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);

        const imgWidth = this.images[this.displayIndex].naturalWidth;
        const imgHeight = this.images[this.displayIndex].naturalHeight;
        const x = Math.abs((this.canvas.width - imgWidth) / 2);
        const y = Math.abs((this.canvas.height - imgHeight) / 2);
        // get the scale
        var scale = Math.abs(Math.min(this.canvas.width / imgWidth, this.canvas.height / imgHeight));
         // get the top left position of the image
        var xPos = (this.canvas.width / 2) - (imgWidth / 2) * scale;
        var yPos = (this.canvas.height / 2) - (imgHeight / 2) * scale;

        if (this.cover) {
          this.drawImageCover(this.ctx, this.images[this.displayIndex]);
        } else {
            this.ctx.drawImage(this.images[this.displayIndex], xPos, yPos, imgWidth*scale, imgHeight * scale);
        }
      }

      resize() {
        const w = this.container.clientWidth;
        const h = this.container.clientHeight;
        this.canvas.style.height = `${h}px`;
        this.canvas.style.width = `${w}px`;
        this.canvas.height = h;
        this.canvas.width = w;

        this.renderIndex(this.displayIndex);
      }

      /**
       * By Ken Fyrstenberg Nilsen
       *
       * drawImageProp(context, image [, x, y, width, height [,offsetX, offsetY]])
       *
       * If image and context are only arguments rectangle will equal canvas
       */
      drawImageCover(ctx, img, x, y, w, h, offsetX, offsetY) {

        if (arguments.length === 2) {
          x = y = 0;
          w = ctx.canvas.width;
          h = ctx.canvas.height;
        }

        // default offset is center
        offsetX = typeof offsetX === "number" ? offsetX : 0.5;
        offsetY = typeof offsetY === "number" ? offsetY : 0.5;

        // keep bounds [0.0, 1.0]
        if (offsetX < 0) offsetX = 0;
        if (offsetY < 0) offsetY = 0;
        if (offsetX > 1) offsetX = 1;
        if (offsetY > 1) offsetY = 1;

        var iw = img.width,
            ih = img.height,
            r = Math.min(w / iw, h / ih),
            nw = iw * r,   // new prop. width
            nh = ih * r,   // new prop. height
            cx, cy, cw, ch, ar = 1;

        // decide which gap to fill
        if (nw < w) ar = w / nw;
        if (Math.abs(ar - 1) < 1e-14 && nh < h) ar = h / nh;  // updated
        nw *= ar;
        nh *= ar;

        // calc source rectangle
        cw = iw / (nw / w);
        ch = ih / (nh / h);

        cx = (iw - cw) * offsetX;
        cy = (ih - ch) * offsetY;

        // make sure source rectangle is valid
        if (cx < 0) cx = 0;
        if (cy < 0) cy = 0;
        if (cw > iw) cw = iw;
        if (ch > ih) ch = ih;

        // fill image in dest. rectangle
        ctx.drawImage(img, cx, cy, cw, ch,  x, y, w, h);
      }
    }
    class ImgLoader extends EventEmitter {
      constructor(opts) {
        super();
        this.images = opts.imgsRef;
        this.imageNames = opts.images;
        this.sequenceLength = opts.images.length;
        this.priorityFranes = opts.priorityFrames;
        this.complete = false;
        this.loadIndex = 0;
        this.priorityQueue = this.createPriorityQueue();
        this.loadingQueue = this.createLoadingQueue();

        this.loadNextImage();
      }

      loadImage(e) {
        if (this.images[e]) {
          return this.loadNextImage();
        }
        const onLoad = () => {
          img.removeEventListener('load', onLoad);
          this.images[e] = img;

          if (e === 0) {
            this.emit('FIRST_IMAGE_LOADED');
          }
          this.loadNextImage();
        }
        const img = new Image;
        img.addEventListener('load', onLoad);
        img.src = this.imageNames[e];
      }

      loadNextImage() {
        if (this.priorityQueue.length) {
          this.loadImage(this.priorityQueue.shift());
          if (!this.priorityQueue.length) {
            this.emit('PRIORITY_IMAGES_LOADED');
          }
        } else if (this.loadingQueue.length) {
          this.loadImage(this.loadingQueue.shift())
        } else {
          this.complete = true;
          this.emit('IMAGES_LOADED');
        }
      }

      createPriorityQueue() {
        const p = this.priorityFrames || [];
        if (!p.length) {
          p.push(0);
          p.push(Math.round(this.sequenceLength / 2));
          p.push(this.sequenceLength - 1);
        }
        return p;
      }

      createLoadingQueue() {
        return this.imageNames.map((s, i) => i).sort((e, n) => {
          return Math.abs(e - this.sequenceLength / 2) - Math.abs(n - this.sequenceLength / 2)
        });
      }
    }
    class ScrollSequence {
      constructor(opts) {
        this.opts = {
          container: '.scroll-sequence__content',
          starts: 'out',
          ends: 'out',
          cover: false,
          ...opts
        }
        this.container = typeof opts.container === 'object' ?
            opts.container :
            document.querySelector(opts.container);

        this.scrollWith = !opts.scrollWith ?
            this.container :
            typeof opts.scrollWith === 'object' ?
                opts.scrollWith :
                document.querySelector(opts.scrollWith);

        this.images = Array(opts.images.length);
        this.imagesToLoad = opts.images;
        this.priorityFrames = opts.priorityFrames;

        this.loader = new ImgLoader({
          imgsRef: this.images,
          images: this.imagesToLoad,
          priorityFrames: this.priorityFrames
        });

        this.canvas = new Canvas({
          container: this.container,
          images: this.images,
          cover: this.opts.cover
        });

        this.init();
      }

      init() {
        this.canvas.setup();
        this.loader.once('FIRST_IMAGE_LOADED', () => {
          this.canvas.renderIndex(0);
        })
        this.loader.once('PRIORITY_IMAGES_LOADED', () => {
          window.addEventListener('scroll', () => this.changeOnWindowScroll());
        })
        this.loader.once('IMAGES_LOADED', () => {
          console.log('Sequence Loaded');
        })
      }

      changeOnWindowScroll() {
        const step = 100 / (this.images.length - 1);
        const mapToIndex = Math.floor((this.percentScrolled) / step);
        requestAnimationFrame(() => this.canvas.renderIndex(mapToIndex));
      }

      get percentScrolled() {
        let h = document.documentElement,
            b = document.querySelector('.scroll-sequence__content'),
            st = 'scrollTop',
            sh = 'scrollHeight';

        return (h[st]||b[st]) / ((h[sh]||b[sh]) - (h.clientHeight)) * 100;
      }
    }

    const mapSequence = new ScrollSequence({
      container: '.map-sequence',
      scrollWith: '.map-container',
      images: mapSequenceImages,
      priorityFrames: [0, 20, 40, 60, 90],
      cover: false,
      playUntil: 'scroll-in',
      starts: 'in'
    });
  }
});
