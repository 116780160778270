$(function () {

    let deps = php_vars.loginDeps;
    let tourneesColor = php_vars.tourneesColor;

    let newDeps = deps.map(v => v.code_departement.toString().padStart(5, 'FR-'));
    let depsColor = []
    newDeps.forEach(dep => {
        let idx = dep
        depsColor[idx] = tourneesColor
    })

   let map = new jvm.Map({
            container: $('#login_map'),
            map: 'fr_merc',
            backgroundColor: 'white',
            enableZoom: false,
            zoomOnScroll: false,
            hoverColor: true,
            series: {
                regions: [{
                    attribute: 'fill',
                    values: depsColor
                }]
            },
            regionStyle: {
                initial: {
                    fill: '#D2EDFF'
                },
                hover: {
                    fill: '#021A79'
                },
            },
            
            onRegionClick: function(e, code){
                let url = deps.find(v => v.code_departement == code.slice(3))
                window.open(url.login_link, '_blank');

            }
        });

       
});
